<template>
	<v-simple-table fixed-header height="auto" class="kotoba-table rounded-t-xl">
		<template v-slot:default>
			<thead>
				<tr class="rounded-sm">
					<th
						class="table-col text-center red lighten-1 white--text rounded-tl-xl"
					>
						たんご
					</th>
					<th class="table-col text-center red lighten-1 white--text">
						かんじ
					</th>
					<th
						class="table-col text-center red lighten-1 white--text rounded-tr-xl"
					>
						いみ
					</th>
				</tr>
			</thead>

			<tbody v-if="datas.length < 1" transition="fade-transition">
				<tr v-for="i in 10" :key="i">
					<td class="text-center">
						<v-skeleton-loader
							type="table-cell"
							transition="fade-transition"
						></v-skeleton-loader>
					</td>
					<td class="text-center">
						<v-skeleton-loader
							type="table-cell"
							transition="fade-transition"
						></v-skeleton-loader>
					</td>
					<td class="text-center">
						<v-skeleton-loader
							type="table-cell"
							transition="fade-transition"
						></v-skeleton-loader>
					</td>
				</tr>
			</tbody>

			<tbody v-else>
				<tr v-for="item in datas" :key="item.name">
					<td class="text-center">{{ item.vocabulary.trim() }}</td>
					<td class="text-center">{{ item.kanji.trim() }}</td>
					<td class="text-center">{{ item.translate.trim() }}</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>
<script>
export default {
	props: { datas: { type: Array, default: () => [] } },
};
</script>
<style scoped>
.kotoba-table {
	background-color: rgba(255, 255, 255, 0.7) !important;

	height: 100%;
}
.table-col {
	width: 33%;
}
.table-header {
	background-color: red;
}
</style>
