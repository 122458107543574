<template>
	<div>
		<AppBar back="/" title="Hasil Tes"></AppBar>

		<v-card v-for="(result, i) in results" :key="i" class="my-1">
			<div class="d-flex justify-space-between align-start px-4">
				<h3>{{ formatRoomName(i) }}</h3>
				<small class="grey--text mt-1">
					{{ dayjs(result.start * 1000).format("DD MMMM YYYY") }}
				</small>
			</div>

			<v-card-text class="d-flex pt-1 justify-space-around">
				<div class="d-flex flex-column justify-center text-center">
					<div>Skor</div>
					<h3 class="orange--text">
						{{ Math.ceil((result.correct / result.question) * 100) }}
						({{ result.correct }}/{{ result.question }})
					</h3>
				</div>
				<div class="d-flex flex-column justify-center text-center">
					<div>Status</div>
					<h3 :class="getStatus(result.correct, result.question).class">
						{{ getStatus(result.correct, result.question).status }}
					</h3>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";
import dayjs from "dayjs";
export default {
	components: { AppBar },
	data() {
		return {
			results: [],
			dayjs,
		};
	},
	mounted() {
		this.$store.commit("SET_HEADER", {
			goBack: "tes-menu",
			headerTitle: "Hasil Tes",
		});
		this.getResults();
	},
	methods: {
		formatRoomName(i) {
			const type = this.results[i].type;
			if (type == "hiragana" || type == "katakana") {
				return "Room " + type.charAt(0).toUpperCase() + type.slice(1);
			}
			if (type == "suuji") {
				return (
					"Room " +
					this.results[i].suujiRoom.room.charAt(0).toUpperCase() +
					this.results[i].suujiRoom.room.slice(1)
				);
			}
			if (type == "bunpou" && this.results[i].roomIds == null) {
				if (this.results[i].bunpouRoom) {
					return (
						"Bunpou " +
						this.results[i].bunpouRoom.name.charAt(0).toUpperCase() +
						this.results[i].bunpouRoom.name.slice(1)
					);
				} else {
					return "Bunpou ?";
				}
			} else if (type == "bunpou" && this.results[i].roomIds !== null) {
				return "Bunpou Group Room";
			}
			return "Room Kanji " + this.results[i].roomId;
		},
		getStatus(correct, question) {
			const percentage = (correct / question) * 100;
			let result;
			const batasLulus = 80;
			if (percentage >= batasLulus) {
				result = {
					status: "Lulus",
					class: "blue--text",
				};
				return result;
			}
			result = {
				status: "Tidak Lulus",
				class: "orange--text",
			};
			return result;
		},
		getDate() {},
		async getResults() {
			try {
				const { data } = await this.$axios.get("mobile/test", {
					params: {
						perPage: 300,
						page: 1,
						sort: { field: "start", type: "desc" },
					},
				});
				if (data.status == "success") {
					this.results = data.data.rows;
					console.log(data.data.rows);
				} else {
					console.log("error");
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style scoped>
.kotoba-table {
	background-color: rgba(255, 255, 255, 0.7) !important;
	height: 80vh;
}
.table-col {
	width: 33%;
}
.table-header {
	background-color: red;
}
</style>
