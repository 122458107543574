<template>
  <div class="container-menu">
    <v-row class="mt-4" no-gutters>
      <v-col class="d-flex justify-center">
        <v-card
          dark
          outlined
          class="elevation-7 menu-button rounded-circle red dark d-flex flex-column justify-center align-center pa-2 text-center"
          @click="goTo('/hiragana')"
        >
          <h1>は</h1>
          <h3>Hiragana</h3>
        </v-card>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-card
          dark
          outlined
          class="elevation-7 menu-button rounded-circle red dark d-flex flex-column justify-center align-center pa-2 text-center"
          @click="goTo('/katakana')"
          ><h1>カ</h1>
          <h3>Katakana</h3>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-4" no-gutters>
      <v-col class="d-flex justify-center">
        <v-card
          dark
          outlined
          class="elevation-7 menu-button rounded-circle red dark d-flex flex-column justify-center align-center pa-2 text-center"
          @click="goTo('/bunpou-room')"
        >
          <h1>文法</h1>
          <h3>Bunpou</h3>
        </v-card>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-card
          dark
          outlined
          class="flex-column elevation-7 menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
          @click="goTo('/kanji-room')"
        >
          <h1>漢字</h1>
          <h3>Kanji</h3>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-4" no-gutters>
      <v-col class="d-flex justify-center">
        <v-card
          dark
          outlined
          class="elevation-7 menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
          @click="goTo('/suuji-room')"
        >
          <h3>Suuji</h3>
        </v-card>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-card
          dark
          outlined
          class="elevation-7 menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
          @click="goTo('/tes-menu')"
        >
          <h3>Tes</h3>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-12" no-gutters>
      <v-col class="d-flex justify-center">
        <v-btn
          @click="logout()"
          class="d-block font-weight-bold"
          dark
          elevation="2"
          color="red"
        >
          Logout</v-btn
        >
      </v-col>
      <v-col class="d-flex justify-center">
        <v-btn
          @click="clearLS()"
          class="d-block font-weight-bold"
          dark
          elevation="2"
          color="red"
        >
          Clear Cache</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cookie from "@/plugins/cookie";
export default {
  name: "HomeView",
  methods: {
    goTo(route) {
      this.$router.push(route);
    },
    logout() {
      cookie.eraseCookie("lpk", null);
      this.clearLS()
      window.location = "/login";
      //this.$router.push("/login");
    },
    clearLS() {
      localStorage.clear();
    },
  },
};
</script>
<style scoped>
.container-menu {
  margin-top: 80px;
}
.menu-button {
  width: 150px;
  height: 150px;
  border-width: 3px !important;
}
</style>
