<template>
	<div>
		<AppBar></AppBar>
		<v-card
			class="pa-1 card-description rounded-sm d-flex justify-center align-top"
		>
			<div class="container-description" v-html="description"></div>
		</v-card>
	</div>
</template>
<script>
import AppBar from "../../components/AppBar.vue";
export default {
	components: { AppBar },
	data() {
		return {
			description: "",
			title: "",
		};
	},
	async mounted() {
		await this.getData();
		this.$store.commit("SET_HEADER", {
			headerTitle: this.title,
			goBack: "bunpou-room",
		});
	},
	methods: {
		async getData() {
			try {
				const { data } = await this.$axios.get(
					"bunpou/" + this.$route.params.id
				);
				this.description = data.data.description
					//.replace(/<p><br><\/p>/g, "")
					//.replace(/<p>&nbsp;<\/p>/g, "")
					.replace(/<p class="ql-align-justify"><br><\/p>/g, "");
				this.title = "Bunpou " + data.data.name;
				this.$store.commit("SET_HEADER", {
					headerTitle: "Bunpou " + data.data.name,
					goBack: "bunpou-room",
				});
				window.scrollTo(0, 1);
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style scoped>
.card-description {
	height: auto;
	width: 100%;
}
</style>
<style>
.container-description p {
	margin-bottom: 0px !important;
}
.container-description table,
th,
td {
	border: 1px solid rgb(235, 147, 15);
	border-collapse: collapse;
	padding: 2px;
}
</style>
