<template>
	<div class="container-menu">
		<AppBar></AppBar>
		<v-row class="mt-4" no-gutters>
			<!-- <v-col class="d-flex justify-center">
				<v-card
					dark
					outlined
					class="elevation-7 menu-button rounded-circle red dark d-flex flex-column justify-center align-center pa-2 text-center"
					@click="goTo('/tes')"
				>
					<h3>Ikuti Tes</h3>
				</v-card>
			</v-col> -->
			<v-col class="d-flex justify-center">
				<v-card
					dark
					outlined
					class="elevation-7 menu-button rounded-circle red dark d-flex flex-column justify-center align-center pa-2 text-center"
					@click="goTo('/all-result')"
				>
					<h3>Lihat Hasil</h3>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
export default {
	components: { AppBar },
	name: "HomeView",
	mounted() {
		this.$store.commit("SET_HEADER", {
			goBack: "/",
			headerTitle: "Tes Menu",
		});
	},
	methods: {
		goTo(route) {
			this.$router.push(route);
		},
	},
};
</script>
<style scoped>
.container-menu {
	margin-top: 80px;
}
.menu-button {
	width: 150px;
	height: 150px;
	border-width: 3px !important;
}
</style>
