import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		headerTitle: "",
		goBack: "",
		gotoPart: "0",
	},
	mutations: {
		SET_HEADER(state, payload) {
			state.headerTitle = payload.headerTitle;
			state.goBack = payload.goBack;
		},
		SET_GOTOPART(state, payload) {
			state.gotoPart = payload.gotoPart;
		},
	},
	getters: {},
	actions: {},
	modules: {},
});
