<template>
	<div>
		<AppBar></AppBar>
		<div class="mb-4 mt-3 d-flex justify-center align-middle">
			<v-card
				class="mb-3 big-kanji rounded-xl pa-7 d-flex justify-center align-center text-h2 text-center"
			>
				<h3>
					{{ word }}<br />
					<h5 class="text-h5 mt-4">{{ translate.toLowerCase() }}</h5>
				</h3>
			</v-card>
		</div>

		<div class="">
			<div
				v-for="(letter, index) in arrayWord"
				:key="index"
				class="d-flex align-center mb-4"
			>
				<template v-if="letter.match(/[\u3400-\u9FBF]/g)">
					<v-card
						class="mr-5 desc-kanji rounded-xl d-flex justify-center align-center text-h4 text-center"
					>
						<div>{{ letter }}</div>
					</v-card>
					<div>
						<h3>くん：{{ kun[index] }}</h3>
						<h3>おん：{{ on[index] }}</h3>
					</div>
				</template>
			</div>
			<!-- <div class="d-flex align-center">
        <v-card
          class="mt-5 mr-5 desc-kanji rounded-xl d-flex justify-center align-center text-h4 text-center"
        >
          <div>年</div>
        </v-card>
        <div>くん：とし おん：ねん</div>
      </div> -->
		</div>
	</div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";
export default {
	components: { AppBar },
	data() {
		return {
			tes: "",
			word: "",
			translate: "",
			kun: [],
			on: [],
			back: "",
		};
	},
	mounted() {
		this.getData();
	},
	computed: {
		arrayWord() {
			console.log("arr word", Array.from(this.word));
			return Array.from(this.word);
		},
	},
	methods: {
		async getData() {
			try {
				const { data } = await this.$axios.get(
					"/kanji/" + this.$route.params.id,
					{}
				);
				this.word = data.data.kanji;
				this.translate = data.data.translate;
				this.kun = data.data.kun;
				this.on = data.data.on;
				this.back = data.data.room.replace("room ", "");

				this.loading = false;
				this.$store.commit("SET_HEADER", {
					headerTitle: "Kanji " + this.word,
					goBack: "kanji/" + this.back,
				});
				window.scrollTo(0, 1);
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style scoped>
.big-kanji {
	width: auto;
	height: auto;
	max-height: 200px;
}
.desc-kanji {
	width: 10vh;
	height: 10vh;
	max-width: 100px;
	max-height: 100px;
}
</style>
