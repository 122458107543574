<template>
  <v-dialog v-model="localModelValue" max-width="290">
    <v-card>
      <v-card-title class="text-h5"> {{ title }} </v-card-title>

      <v-card-text> {{ message }} </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <div v-if="isConfirm">
          <v-btn color="grey darken-1" text @click="$emit('negativeConfirm')">
            Tidak
          </v-btn>

          <v-btn color="red darken-1" text @click="$emit('positifConfirm')">
            Ya
          </v-btn>
        </div>

        <div v-else>
          <v-btn color="red darken-1" text @click="$emit('ok')"> OK </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    title: String,
    message: String,
    isConfirm: Boolean,
    modelValue: Boolean,
  },
  computed: { 
      localModelValue: {
        get() {
            return this.modelValue
        },
        set(newValue) {
            this.$emit('update:modelValue', newValue)
        },
    },
  }
};
</script>
