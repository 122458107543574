<template>
	<div class="">
		<AppBar></AppBar>

		<TableData fixed-header :datas="tableData"></TableData>
	</div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";
import TableData from "@/components/TableData.vue";
export default {
	components: { AppBar, TableData },
	data() {
		return {
			dialog: false,
			tableData: [],
			title: "",
		};
	},
	async mounted() {
		await this.getData();
		this.$store.commit("SET_HEADER", {
			headerTitle: this.title,
			goBack: "bunpou-room",
		});
	},
	methods: {
		async getData() {
			try {
				const { data } = await this.$axios.get(
					"bunpou/" + this.$route.params.id
				);
				this.tableData = data.data.vocabularies;
				this.title = "Bunpou " + data.data.name;
				window.scrollTo(0, 1);
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style>
.text-small {
	font-size: 8px;
}
</style>
