import axios from "axios";
import Vue from "vue";
import cookie from "@/plugins/cookie";
import { BASE_URI } from "@/plugins/utilities";

document.onselectstart = new Function("return false");

const devInstance = createInstance(BASE_URI + "/api/");
// const productionInstance = createInstance("http://localhost:3000"); // will change later

function createInstance(baseURL) {
	const api = axios.create({
		baseURL: baseURL,
		headers: {
			"Content-type": "application/json",
			Authorization: {
				toString() {
					const tokenObj = JSON.parse(cookie.getCookie("lpk"));
					let token = tokenObj ? tokenObj.token : null;
					return token ? `Bearer ${token}` : null;
				},
			},
		},
	});

	api.interceptors.request.use(
		function (config) {
			config.params = {
				t_: Date.now(),
				...config.params,
			};
			return config;
		},
		function (error) {
			console.log("Error", error.message);
			return Promise.reject(error);
		}
	);

	api.interceptors.response.use(
		function (config) {
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	return api;
}

export default {
	install() {
		Vue.prototype.$axios = devInstance;
	},
};
