<template>
	<div>
		<AppBar></AppBar>
		<div v-if="tampilAnswer.length > 0" class="tes-section">
			<div>
				<v-card
					class="d-flex justify-center align-center pa-3 rounded-xl card-question"
				>
					<div v-html="tampilAnswer[0].question.description"></div>
				</v-card>
			</div>
			<div
				class="d-flex mt-7 flex-column flex-wrap justify-center align-center justify-space-around"
			>
				<v-card
					v-for="(opt, index) in tampilAnswer[0].question.options"
					:key="index"
					@click="setAnswer(tampilAnswer[0].id, index)"
					fluid
					color="white"
					class="card-answer rounded-lg pa-2 mt-4 text-center d-flex block align-center justify-center"
					><h4>{{ opt }}</h4>
				</v-card>
			</div>
			<v-card outlined class="countdown elevation-2 pa-2">
				<small
					>Sisa Waktu {{ date.minutes.toString().padStart(2, "0") }}:{{
						date.seconds.toString().padStart(2, "0")
					}}</small
				>
			</v-card>
			<v-snackbar v-model="dialog.show" :timeout="2000">
				{{ dialog.title }}

				<template v-slot:action="{ attrs }">
					<v-btn color="blue" text v-bind="attrs" @click="dialog.show = false">
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<v-dialog v-model="loading" persistent width="300">
				<v-card color="red" dark>
					<v-card-text>
						Mengirim jawaban
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
		<DialogMessage
			:modelValue.sync="tesDialog"
			:title="dialogTitle"
			:message="dialogMessage"
			:isConfirm="false"
			@ok="testFinish()"
		></DialogMessage>
	</div>
</template>
<script>
import AppBar from "../components/AppBar.vue";
import DialogMessage from "../components/DialogMessage.vue";
export default {
	components: { AppBar, DialogMessage },
	data() {
		return {
			dialog: {
				show: false,
				color: "",
				title: "",
				desc: "",
				background: "@/assets/background/index.jpg",
			},
			tesTitle: "",
			loading: false,
			startTime: 0,
			endTime: 0,
			answerData: [],
			localAnswer: [],
			tampilAnswer: [],
			tesDialog: false,
			dialogTitle: "",
			dialogMessage: "",
			tesCode: "",
			tesType: "",
			date: {
				days: "",
				hours: "",
				minutes: "",
				seconds: "",
			},
		};
	},
	mounted() {
		this.getQuestion();
		this.interval = setInterval(this.setCountDown, 1000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {
		async testFinish() {
			await this.$store.commit("SET_HEADER", {
				goBack: this.$store.state.goBack,
				headerTitle: "Tes " + this.tesTitle,
			});
			this.tesDialog = false;
			this.$router.push("/test-result/" + this.tesCode);
		},
		async getQuestion() {
			try {
				const { data } = await this.$axios.get(
					"mobile/test/" + this.$route.params.id
				);
				if (data.status == "success") {
					console.log("data tes", data);
					this.answerData = data.data.asnwers;
					this.tampilAnswer = data.data.answers;
					this.startTime = data.start;
					this.endTime = data.data.end;
					this.tesTitle = data.data.type;
					this.tesCode = data.data.code;

					// localStorage.setItem("answers", data.data.answers);
				} else {
					console.log("error");
				}
			} catch (error) {
				console.log(error);
			}
		},
		async setAnswer(id, answer) {
			// console.log('id:'+id+'|'+Object.keys(answer))
			// TODO - handle sudah terjawab
			this.loading = true;
			try {
				const { data } = await this.$axios.put("mobile/test/" + id, {
					answer: answer,
				});
				if (data.status == "success") {
					if (data.data.correct == true) {
						this.dialog.desc = "Benar!";
						this.dialog.title = "Benar!";
						this.dialog.color = "green";
						this.dialog.show = true;
					} else {
						this.dialog.desc = "Salah!";
						this.dialog.title = "Salah!";
						this.dialog.color = "red";
						this.dialog.show = true;
					}
				} else if (data.data.end < parseInt(Date.now() / 1000)) {
					this.dialog.desc = "Waktu habis!";
					this.dialog.title = "Waktu habis!";
					this.dialog.color = "red";
					this.dialog.show = true;
					setTimeout(() => {
						this.testFinish();
					}, 2000);
				} else {
					console.log(data.message);
				}
				this.tampilAnswer.shift();
				if (this.tampilAnswer.length <= 0) {
					this.testFinish();
				}
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		},

		setCountDown() {
			this.startTime, this.endTime * 1000;
			const timeleft = this.endTime * 1000 - new Date().getTime();
			let date = {};

			date.days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
			date.hours = Math.floor(
				(timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			date.minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
			date.seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
			if (date.minutes <= 0 && date.seconds <= 0) {
				clearInterval(this.interval);
				date.minutes = 0;
				date.seconds = 0;
				this.dialogTitle = "Waktu Habis";
				this.dialogMessage = "Waktu mengerjakan sudah habis";
				this.tesDialog = true;
			}
			this.date = date;
		},
	},
};
</script>
<style>
.tes-section {
	margin-bottom: 100px !important;
}
.card-answer {
	width: 100% !important;
	min-height: 50px;
}
.card-question {
	min-height: 150px;
}
.countdown {
	position: fixed;
	bottom: 15px;
	left: 15px;
	z-index: 10;
	border-width: 1px;
	border-color: red !important;
	border-style: solid;
}
</style>
