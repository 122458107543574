<template>
	<div>
		<AppBar></AppBar>

		<v-row v-if="loading" justify="center" class="mr-7 ml-7 pr-7 pl-7">
			<v-col md="8">
				<v-skeleton-loader
					v-for="i in 5"
					:key="i"
					type="table-heading, table-thead"
				></v-skeleton-loader>
			</v-col>
		</v-row>

		<div class="justify-center d-flex flex-column">
			<div class="mb-7">
				<template v-for="(item, index) in tableData2">
					<div :key="index + 'div'" :ref="'bunpou_' + index">
						<v-card
							v-if="item.type !== 'tes'"
							:key="index"
							fluid
							width="100%"
							outlined
							class="rounded-xl mb-2"
						>
							<v-card-title class="text-center pb-1 pb-1 pt-1">
								{{ item.name }}
							</v-card-title>
							<v-card-text
								class="pb-2 d-flex justify-space-around align-center"
							>
								<div class="d-flex flex-column align-center justify-center">
									<v-btn
										@click="goToKosakata(item.id, 'bunpou_' + index)"
										class="mb-2 mx-5 flex xxl"
										x-large
										fab
										dark
										color="error"
									>
										<v-icon>mdi-chat</v-icon>
									</v-btn>
									<div>Kosakata</div>
								</div>

								<div class="d-flex flex-column align-center justify-center">
									<v-btn
										@click="goToPolaKalimat(item.id, 'bunpou_' + index)"
										class="mb-2 mx-5 flex xxl"
										x-large
										fab
										dark
										color="error"
									>
										<v-icon>mdi-book-open-variant</v-icon>
									</v-btn>
									<div>Pola Kalimat</div>
								</div>

								<div class="d-flex flex-column align-center justify-center">
									<v-btn
										@click.stop="
											showDialog(
												true,
												'Ikuti Tes',
												'Apakah anda akan mengikuti tes?',
												index,
												'bunpou_' + index
											)
										"
										class="mb-2 mx-5 flex xxl"
										x-large
										fab
										dark
										color="error"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
									<div>Tes</div>
								</div>
							</v-card-text>
						</v-card>

						<v-btn
							v-if="item.type == 'tes'"
							:key="index + 'x'"
							block
							text
							outlined
							color="red"
							class="rounded-large mb-2 elevation-0 btn-tes"
							@click.stop="
								showDialog(
									true,
									'Ikuti Tes',
									'Apakah anda akan mengikuti tes?',
									index
								)
							"
						>
							<v-card-title class="text-center pb-1 pb-1 pt-1">
								{{ item.name }}
							</v-card-title>
						</v-btn>
					</div>
				</template>
			</div>

			<!-- <v-btn class="my-7" fixed bottom x-large color="primary">Back</v-btn> -->
		</div>
		<DialogMessage
			:modelValue.sync="tesDialog"
			:title="dialogTitle"
			:message="dialogMessage"
			:isConfirm="modeTes"
			@negativeConfirm="tesDialog = false"
			@positifConfirm="ikutiTes()"
			@ok="tesDialog = false"
		></DialogMessage>
	</div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import DialogMessage from "@/components/DialogMessage.vue";
import { mapMutations } from "vuex";
import ls from "@/plugins/lsttl";
export default {
	components: { AppBar, DialogMessage },
	name: "HomeView",
	data() {
		return {
			tableData: [],
			loading: true,
			tesDialog: false,
			dialogTitle: "Ikuti Tes",
			dialogMessage: "Apakah anda akan mengikuti tes?",
			modeTes: true,
			tableData2: [],
			selectedIds: [],
			selectedIndex: null,
		};
	},
	mounted() {
		this.getData();
		this.$store.commit("SET_HEADER", {
			headerTitle: "Bunpou",
			goBack: "/",
		});
	},
	methods: {
		showDialog(modeTes, title, message, i, gotopart) {
			this.modeTes = modeTes;
			this.dialogTitle = title;
			this.dialogMessage = message;
			this.tesDialog = true;
			// console.log("table2", this.tableData2[i].id);
			this.selectedIds = this.tableData2[i].id;
			this.selectedIndex = i;
			ls.setLs("lpkbunpou_gotopart", gotopart, 86400 * 2);
		},
		...mapMutations(["SET_HEADER"]),
		goToKosakata(id, gotopart) {
			// this.$store.commit("SET_GOTOPART", {
			// 	gotoPart: gotopart,
			// });
			ls.setLs("lpkbunpou_gotopart", gotopart, 86400 * 2);
			this.$router.push({
				name: "bunpou-kotoba",
				params: { id: id },
			});
		},
		goToPolaKalimat(id, gotopart) {
			// this.$store.commit("SET_GOTOPART", {
			// 	gotoPart: gotopart,
			// });
			ls.setLs("lpkbunpou_gotopart", gotopart, 86400 * 2);
			this.$router.push({
				name: "bunpou-pola-kalimat",
				params: { id: id },
			});
		},
		gotoPart(refName = "") {
			let vm = this;
			setTimeout(() => {
				let element = vm.$refs[refName];
				// console.log(element);
				if (element) {
					// console.log(element[0].children[0]);
					element[0].children[0].classList.value =
						element[0].children[0].classList.value + " selectedBefore";
					// element[0].children[0].style.borderColor = "blue";
					let top = element[0].offsetTop - element[0].offsetHeight;
					window.scrollTo(0, top);
				}
			}, 500);
		},
		async ikutiTes() {
			// console.log("tableData2", this.tableData2[this.selectedIndex]);

			try {
				const { data } = await this.$axios.post("mobile/test", {
					type: "bunpou",
					roomId: this.tableData2[this.selectedIndex].id
						? this.tableData2[this.selectedIndex].id
						: null,
					roomIdGroup: this.tableData2[this.selectedIndex].ids
						? this.tableData2[this.selectedIndex].ids
						: null,
				});

				if (data.status == "success") {
					console.log("bunpou", data.data);
					this.tesDialog = false;
					this.$store.commit("SET_HEADER", {
						headerTitle:
							"Tes Bunpou " + this.tableData2[this.selectedIndex].name,
						goBack: "bunpou-room",
					});
					this.$router.push("/tes/" + data.data.test.code);
				} else {
					this.showDialog(false, "Belum ada tes", "Soal-soal belum tersedia");
					console.log("error");
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getData() {
			try {
				let tblData = ls.getLs("lpkbunpou");
				if (tblData == null) {
					const { data } = await this.$axios.get("/bunpou", {
						params: {
							perPage: 300,
							page: 1,
							sort: { field: "ordinal", type: "asc" },
						},
					});
					tblData = data.data.rows;
					ls.setLs("lpkbunpou", tblData, 86400 * 2);
				}

				this.tableData = tblData;
				let ids = [];
				let group = 5;
				for (let i = 0; i < this.tableData.length; i++) {
					const el = this.tableData[i];
					this.tableData2.push(el);
					ids.push(el.id);
					if ((i + 1) % group == 0) {
						this.tableData2.push({
							ids: ids,
							type: "tes",
							name: "tes bab " + (i + 2 - group) + "-" + (i + 1),
						});
						// console.log("ids", ids);
						ids = [];
					}
				}
				// console.log(this.tableData2);
				// console.log(this.tableData);

				this.loading = false;
				this.gotoPart(ls.getLs("lpkbunpou_gotopart"));
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style scoped>
.btn-tes {
	background-color: #fff;
}
.selectedBefore {
	background: rgb(216, 199, 199) !important;
	border-style: solid;
	border-color: red;
}
</style>
