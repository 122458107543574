<template>
	<div>
		<AppBar></AppBar>

		<!-- <v-row v-if="loading" justify="center" class="mr-7 ml-7 pr-7 pl-7">
      <v-col md="8">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          v-bind="attrs"
          type="table-heading, table-thead"
        ></v-skeleton-loader>
      </v-col>
    </v-row> -->

		<div class="justify-center d-flex flex-column">
			<div class="mb-7">
				<template v-for="index in 5">
					<v-card
						:key="index"
						fluid
						width="100%"
						outlined
						class="rounded-xl mb-2"
					>
						<v-card-title class="text-center pb-1 pb-1 pt-1">
							{{ "Kanji Room " + index }}
						</v-card-title>
						<v-card-text class="pb-2 d-flex justify-space-around align-center">
							<div class="d-flex flex-column align-center justify-center">
								<v-btn
									@click="$router.push('kanji/' + index)"
									class="mb-2 mx-5 flex xxl"
									x-large
									fab
									dark
									color="error"
								>
									<v-icon>mdi-chat</v-icon>
								</v-btn>
								<div>Kosakata</div>
							</div>

							<div class="d-flex flex-column align-center justify-center">
								<v-btn
									@click.stop="
										showDialog(
											true,
											'Ikuti Tes',
											'Apakah anda akan mengikuti tes?',
											index
										)
									"
									class="mb-2 mx-5 flex xxl"
									x-large
									fab
									dark
									color="error"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
								<div>Tes</div>
							</div>
						</v-card-text>
					</v-card>
				</template>
				<v-btn
					block
					text
					outlined
					color="red"
					class="rounded-large mb-2 elevation-0 btn-tes"
					@click.stop="
						showDialog(
							true,
							'Ikuti Tes',
							'Apakah anda akan mengikuti tes?',
							[1, 2, 3, 4, 5]
						)
					"
				>
					<v-card-title class="text-center pb-1 pb-1 pt-1">
						Tes Semua Room
					</v-card-title>
				</v-btn>
			</div>

			<!-- <v-btn class="my-7" fixed bottom x-large color="primary">Back</v-btn> -->
		</div>
		<DialogMessage
			:modelValue.sync="tesDialog"
			:title="dialogTitle"
			:message="dialogMessage"
			:isConfirm="modeTes"
			@negativeConfirm="tesDialog = false"
			@positifConfirm="ikutiTes(selectedIds)"
			@ok="tesDialog = false"
		></DialogMessage>
	</div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import DialogMessage from "@/components/DialogMessage.vue";
import { mapMutations } from "vuex";
export default {
	components: { AppBar, DialogMessage },
	name: "HomeView",
	data() {
		return {
			tableData: [],
			loading: true,
			tesDialog: false,
			dialogTitle: "Ikuti Tes",
			dialogMessage: "Apakah anda akan mengikuti tes?",
			modeTes: true,
			tableData2: [],
			selectedIds: [],
		};
	},
	mounted() {
		// this.getData(); //tidakperlu diambil pas awal
		this.$store.commit("SET_HEADER", {
			headerTitle: "Kanji",
			goBack: "/",
		});
	},
	methods: {
		showDialog(modeTes, title, message, ids) {
			this.modeTes = modeTes;
			this.dialogTitle = title;
			this.dialogMessage = message;
			this.tesDialog = true;
			this.selectedIds = ids;
		},
		...mapMutations(["SET_HEADER"]),
		goToKosakata(id) {
			this.$router.push({
				name: "bunpou-kotoba",
				params: { id: id },
			});
		},
		goToPolaKalimat(id) {
			this.$router.push({
				name: "bunpou-pola-kalimat",
				params: { id: id },
			});
		},
		async ikutiTes() {
			try {
				console.log(typeof this.selectedIds);
				// let roomId
				// if(typeof this.selectedIds !== 'object'){
				//     roomId = parseInt(this.selectedIds)
				// }else{
				//     roomId = this.selectedIds
				// }
				const { data } = await this.$axios.post("mobile/test", {
					type: "kanji",
					roomId: this.selectedIds,
				});

				if (data.status == "success") {
					console.log("kanji", data);
					this.tesDialog = false;
					this.$store.commit("SET_HEADER", {
						headerTitle: "Tes Kanji " + data.data.test.roomId,
						goBack: "kanji-room",
					});
					this.$router.push("/tes/" + data.data.test.code);
				} else {
					this.showDialog(false, "Belum ada tes", "Soal-soal belum tersedia");
					console.log("error");
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getData() {
			try {
				const { data } = await this.$axios.get("/kanji", {
					params: {
						perPage: 300,
						page: 1,
						order: { field: "ordinal", type: "desc" },
					},
				});
				this.tableData = data.data.rows;
				let ids = [];
				let group = 4;
				for (let i = 0; i < this.tableData.length; i++) {
					const el = this.tableData[i];
					this.tableData2.push(el);
					ids.push(el.id);
					if ((i + 1) % group == 0) {
						this.tableData2.push({
							ids: ids,
							type: "tes",
							name: "tes room " + (i + 2 - group) + "-" + (i + 1),
						});
						console.log("ids", ids);
						ids = [];
					}
				}
				console.log(this.tableData2);
				console.log(this.tableData);

				this.loading = false;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style>
.btn-tes {
	background-color: #fff;
}
</style>
