var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppBar'),(_vm.loading)?_c('v-row',{staticClass:"mr-7 ml-7 pr-7 pl-7",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8"}},_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"table-heading, table-thead"}})}),1)],1):_vm._e(),_c('div',{staticClass:"justify-center d-flex flex-column"},[_c('div',{staticClass:"mb-7"},[_vm._l((_vm.tableData2),function(item,index){return [_c('div',{key:index + 'div',ref:'bunpou_' + index,refInFor:true},[(item.type !== 'tes')?_c('v-card',{key:index,staticClass:"rounded-xl mb-2",attrs:{"fluid":"","width":"100%","outlined":""}},[_c('v-card-title',{staticClass:"text-center pb-1 pb-1 pt-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-card-text',{staticClass:"pb-2 d-flex justify-space-around align-center"},[_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-btn',{staticClass:"mb-2 mx-5 flex xxl",attrs:{"x-large":"","fab":"","dark":"","color":"error"},on:{"click":function($event){return _vm.goToKosakata(item.id, 'bunpou_' + index)}}},[_c('v-icon',[_vm._v("mdi-chat")])],1),_c('div',[_vm._v("Kosakata")])],1),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-btn',{staticClass:"mb-2 mx-5 flex xxl",attrs:{"x-large":"","fab":"","dark":"","color":"error"},on:{"click":function($event){return _vm.goToPolaKalimat(item.id, 'bunpou_' + index)}}},[_c('v-icon',[_vm._v("mdi-book-open-variant")])],1),_c('div',[_vm._v("Pola Kalimat")])],1),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-btn',{staticClass:"mb-2 mx-5 flex xxl",attrs:{"x-large":"","fab":"","dark":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialog(
											true,
											'Ikuti Tes',
											'Apakah anda akan mengikuti tes?',
											index,
											'bunpou_' + index
										)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('div',[_vm._v("Tes")])],1)])],1):_vm._e(),(item.type == 'tes')?_c('v-btn',{key:index + 'x',staticClass:"rounded-large mb-2 elevation-0 btn-tes",attrs:{"block":"","text":"","outlined":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.showDialog(
								true,
								'Ikuti Tes',
								'Apakah anda akan mengikuti tes?',
								index
							)}}},[_c('v-card-title',{staticClass:"text-center pb-1 pb-1 pt-1"},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_vm._e()],1)]})],2)]),_c('DialogMessage',{attrs:{"modelValue":_vm.tesDialog,"title":_vm.dialogTitle,"message":_vm.dialogMessage,"isConfirm":_vm.modeTes},on:{"update:modelValue":function($event){_vm.tesDialog=$event},"update:model-value":function($event){_vm.tesDialog=$event},"negativeConfirm":function($event){_vm.tesDialog = false},"positifConfirm":function($event){return _vm.ikutiTes()},"ok":function($event){_vm.tesDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }