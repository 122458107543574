<template>
	<div>
		<AppBar></AppBar>
		<v-skeleton-loader v-if="loading" type="table-tbody"></v-skeleton-loader>
		<div class="pa-2">
			<div class="d-flex" fluid v-for="(kat, i) in katakanas" :key="i">
				<v-card
					class="hir-row rounded-xl d-flex mb-1 w-full fluid justify-space-around"
					width="100%"
					outlined
					fluid
				>
					<div
						class="py-2 mx-2 d-flex flex-column align-center justify-center"
						v-for="(h, j) in kat"
						:key="j"
					>
						<v-btn
							v-show="h != null"
							@click="openDialog(i, j)"
							class="d-block"
							:elevation="h != null ? '2' : '0'"
							:color="h != null ? 'red' : 'transparent'"
							:outlined="h != null"
							fab
							small
						>
							<h1 class="h1 pb-1">{{ h != null ? h.kanji : "" }}</h1></v-btn
						>
						<small> {{ h != null ? h.romanji : "" }} </small>
					</div>
				</v-card>
			</div>
			<v-btn
				v-if="!loading"
				block
				dark
				color="red"
				class="mt-1"
				@click.stop="
					showDialog(true, 'Ikuti Tes', 'Apakah anda akan mengikuti tes?')
				"
			>
				Ikuti Tes
			</v-btn>
		</div>

		<DialogMessage
			:modelValue.sync="tesDialog"
			:title="dialogTitle"
			:message="dialogMessage"
			:isConfirm="modeTes"
			@negativeConfirm="tesDialog = false"
			@positifConfirm="ikutiTes()"
			@ok="tesDialog = false"
		></DialogMessage>

		<DetailHuruf
			:hiragana="selectedKat"
			:dialog="dialog"
			@close="dialog = false"
		></DetailHuruf>
	</div>
</template>
<script>
import AppBar from "../components/AppBar.vue";
import DetailHuruf from "../components/DetailHuruf.vue";
import DialogMessage from "../components/DialogMessage.vue";
import ls from "@/plugins/lsttl";
export default {
	components: { AppBar, DetailHuruf, DialogMessage },
	async created() {
		this.getData();
		this.$store.commit("SET_HEADER", {
			headerTitle: "Katakana",
			goBack: "/",
		});
	},
	data() {
		return {
			showHint: false,
			dialog: false,
			x: 0,
			y: 0,
			isDrawing: false,
			canvas: null,
			selectedKat: {},
			loading: true,
			katakanas: [],
			tesDialog: false,
			dialogTitle: "Ikuti Tes",
			dialogMessage: "Apakah anda akan mengikuti tes?",
			modeTes: true,
		};
	},
	methods: {
		showDialog(modeTes, title, message) {
			this.modeTes = modeTes;
			this.dialogTitle = title;
			this.dialogMessage = message;
			this.tesDialog = true;
		},
		async ikutiTes() {
			try {
				const { data } = await this.$axios.post("mobile/test", {
					type: "katakana",
					roomId: 1,
				});

				if (data.status == "success") {
					console.log(data.data.test);
					this.tesDialog = false;
					this.$store.commit("SET_HEADER", {
						headerTitle: "Tes Katakana",
						goBack: "katakana",
					});
					this.$router.push("/tes/" + data.data.test.code);
				} else {
					this.showDialog(false, "Belum ada tes", "Soal-soal belum tersedia");
					console.log("error");
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getData() {
			const kataCookie = ls.getLs("lpkkatakana");
			try {
				if (kataCookie) {
					this.katakanas = kataCookie;
					this.loading = false;
				} else {
					const { data } = await this.$axios.get("/setting/katakana_table");
					if (data.status == "success") {
						this.katakanas = data.data;
						ls.setLs("lpkkatakana", this.katakanas, 86400 * 2);
						this.loading = false;
						// console.log(this.katakanas);
					} else {
						console.log("error");
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		openDialog(i, j) {
			this.selectedKat = this.katakanas[i][j];
			this.dialog = true;
		},
		speech(text) {
			let msg = new SpeechSynthesisUtterance();
			msg.lang = "ja-JP";
			msg.text = text;
			// var myRate = speechSynthesisUtteranceInstance.rate;
			msg.rate = 0.7;
			window.speechSynthesis.speak();
		},
		showModal() {
			this.dialog = true;
		},
		drawLine(x1, y1, x2, y2) {
			let c = document.getElementById("myCanvas");
			this.canvas = c.getContext("2d");
			let ctx = this.canvas;
			ctx.beginPath();
			ctx.strokeStyle = "black";
			ctx.lineWidth = 10;
			ctx.lineCap = "round";
			ctx.lineJoin = "round";
			ctx.moveTo(x1, y1);
			ctx.lineTo(x2, y2);
			ctx.stroke();
			ctx.closePath();
		},
		beginDrawing(e) {
			this.x = e.offsetX;
			this.y = e.offsetY;
			this.isDrawing = true;
		},
		keepDrawing(e) {
			if (this.isDrawing === true) {
				this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
				this.x = e.offsetX;
				this.y = e.offsetY;
			}
		},
		stopDrawing(e) {
			if (this.isDrawing === true) {
				this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
				this.x = 0;
				this.y = 0;
				this.isDrawing = false;
			}
		},
		clearCanvas() {
			let c = document.getElementById("myCanvas");
			this.canvas = c.getContext("2d");
			let ctx = this.canvas;
			// ctx.clear();
			ctx.clearRect(0, 0, 300, 300);
		},
	},
};
</script>
<style scoped>
.letter-indicator {
	width: 20px;
}
.scroll {
	height: 95vh;
	overflow: auto;
}
.letter-card {
	width: 250px;
	height: 250px;
}
.container-card {
	width: 100%;
	height: 43vh;
	display: flex;
	justify-content: center;
}
#canvas {
	width: 100%;
	height: 100%;
}
.hint {
	height: 50px;
}
.hir-row {
	border-width: 1px;
	border-color: red !important;
}
</style>
