<template>
  <div class="">
    <AppBar></AppBar>

    <TableData fixed-header :datas="tableData"></TableData>
  </div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";
import TableData from "@/components/TableData.vue";
export default {
  components: { AppBar, TableData },
  data() {
    return {
      dialog: false,
      tableData: [],
      title: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const { data } = await this.$axios.get(
          "suuji/" + this.$route.params.id
        );
        this.tableData = data.data.vocabularies;
        this.$store.commit("SET_HEADER", {
          headerTitle: "Suuji " + data.data.room.toUpperCase(),
          goBack: "suuji-room",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.text-small {
  font-size: 8px;
}
</style>
