<template>
  <div class="">
    <AppBar></AppBar>
    <v-row v-if="loading" justify="center" class="mr-7 ml-7 pr-7 pl-7">
      <v-col md="8">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          v-bind="attrs"
          type="table-heading, table-thead"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else>
      <v-row v-for="(su, i) in suuji" :key="i" class="mt-4" no-gutters>
        <v-col v-for="(s, j) in su" :key="j" class="d-flex justify-center">
          <v-card
            dark
            outlined
            class="elevation-7 menu-button rounded-circle red dark d-flex flex-column justify-center align-center pa-2 text-center"
            @click="goTo(s.id)"
          >
            <img :src="icons[s.room]" alt="" />
            <h3>{{ s.room.toUpperCase() }}</h3>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-4" no-gutters>
        <v-col class="d-flex justify-center">
          <v-card
            dark
            outlined
            class="elevation-7 menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
            @click.stop="
              showDialog(true, 'Ikuti Tes', 'Apakah anda akan mengikuti tes?')
            "
          >
            <h3>Tes</h3>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <DialogMessage
      :modelValue.sync="tesDialog"
      :title="dialogTitle"
      :message="dialogMessage"
      :isConfirm="modeTes"
      @negativeConfirm="tesDialog = false"
      @positifConfirm="ikutiTes()"
      @ok="tesDialog = false"
    ></DialogMessage>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import DialogMessage from "@/components/DialogMessage.vue";
export default {
  components: { AppBar, DialogMessage },
  name: "HomeView",
  data() {
    return {
      suuji: [],
      loading: true,
      icons: {
        barang: require("@/assets/img/barang.svg"),
        orang: require("@/assets/img/orang.svg"),
        mesin: require("@/assets/img/mesin.svg"),
        waktu: require("@/assets/img/waktu.svg"),
        binatang: require("@/assets/img/binatang.svg"),
      },
      tesDialog: false,
      dialogTitle: "Ikuti Tes",
      dialogMessage: "Apakah anda akan mengikuti tes?",
      modeTes: true,
    };
  },
  mounted() {
    this.getData();
    this.$store.commit("SET_HEADER", {
      headerTitle: "Suuji",
      goBack: "/",
    });
  },
  methods: {
    showDialog(modeTes, title, message) {
      this.modeTes = modeTes;
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.tesDialog = true;
    },
    goTo(id) {
      this.$router.push({ name: "suuji-kotoba", params: { id: id } });
    },
    async ikutiTes() {
      try {
        const { data } = await this.$axios.post("mobile/test", {
          type: "suuji",
          roomId: 1,
        });

        if (data.status == "success") {
          this.tesDialog = false;
          this.$store.commit("SET_HEADER", {
            headerTitle: "Tes Suuji",
            goBack: "suuji-room",
          });
          this.$router.push("/tes/" + data.data.test.code);
        } else {
          this.showDialog(false, "Belum ada tes", "Soal-soal belum tersedia");
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      console.log("getdata");
      try {
        const { data } = await this.$axios.get("/suuji", {
          params: {
            perPage: 3000,
            page: 1,
            order: { field: "id", type: "asc" },
          },
        });

        const array_chunks = (arr, chunk_size) =>
          Array(Math.ceil(arr.length / chunk_size))
            .fill()
            .map((_, index) => index * chunk_size)
            .map((begin) => arr.slice(begin, begin + chunk_size));

        this.suuji = array_chunks(data.data.rows, 2);
        console.log(JSON.stringify(this.suuji));
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.menu-button {
  width: 150px;
  height: 150px;
  border-width: 3px !important;
}
.menu-button img {
  border-radius: 0 !important;
  width: 50px;
  margin-bottom: 10px;
  height: auto;
}
</style>
