<template>
	<div id="lateral">
		<AppBar></AppBar>
		<v-row v-if="loading" justify="center" class="mr-7 ml-7 pr-7 pl-7">
			<v-col md="8">
				<v-skeleton-loader
					v-for="i in 5"
					:key="i"
					type="table-heading, table-thead"
				></v-skeleton-loader>
			</v-col>
		</v-row>
		<div v-else>
			<!-- <button @click="gotoPart('id180')">tot</button> -->
			<v-row v-for="(kanji, i) in kanjiData" :key="i" class="mt-4" no-gutters>
				<v-col v-for="(kan, j) in kanji" :key="j" class="d-flex justify-center">
					<div :ref="'kanji_' + $route.params.id + i + j">
						<v-card
							dark
							outlined
							class="elevation-7 menu-button rounded-circle red dark d-flex flex-column justify-center align-center pa-2 text-center"
							@click="goTo(kan.id, 'kanji_' + $route.params.id + i + j)"
						>
							<h1>{{ kan.kanji.toUpperCase() }}</h1>
						</v-card>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import ls from "@/plugins/lsttl";
export default {
	components: { AppBar },
	name: "HomeView",
	data() {
		return {
			kanjidata: [],
			loading: true,
		};
	},
	mounted() {
		this.getData();
		this.$store.commit("SET_HEADER", {
			headerTitle: "Kanji Room " + this.$route.params.id,
			goBack: "kanji-room",
		});
	},
	methods: {
		goTo(route, gotopart) {
			// this.$store.commit("SET_GOTOPART", {
			// 	gotoPart: gotopart,
			// });
			ls.setLs("lpkkanji_gotopart", gotopart, 86400 * 2);
			this.$router.push("/kanji-detail/" + route);
		},
		gotoPart(refName = "") {
			let vm = this;
			setTimeout(() => {
				let element = vm.$refs[refName];
				// console.log(element);
				if (element) {
					console.log(element[0].children[0]);
					element[0].children[0].classList.value =
						element[0].children[0].classList.value + " selectedBefore";
					// element[0].children[0].style.borderColor = "blue";
					let top = element[0].offsetTop - element[0].offsetHeight;
					window.scrollTo(0, top);
				}
			}, 500);
		},
		async getData() {
			try {
				const { data } = await this.$axios.get("/kanji", {
					params: {
						perPage: 300,
						page: 1,
						room: "room " + this.$route.params.id,
					},
				});
				const array_chunks = (arr, chunk_size) =>
					Array(Math.ceil(arr.length / chunk_size))
						.fill()
						.map((_, index) => index * chunk_size)
						.map((begin) => arr.slice(begin, begin + chunk_size));

				this.kanjiData = array_chunks(data.data.rows, 2);
				this.loading = false;
				this.loading = false;
				// this.gotoPart(this.$store.state.gotoPart);
				this.gotoPart(ls.getLs("lpkkanji_gotopart"));
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style scoped>
.menu-button {
	width: 150px;
	height: 150px;
	border-width: 3px !important;
	border-color: red !important;
}
.text-small {
	font-size: 8px;
}
.selectedBefore {
	border-color: white !important;
	background-color: rgb(191 33 24) !important;
}
</style>
