<template class="hero" >
  <div class="pa-6 ">
    <v-container fluid class="form-container">
      <h1 class="mb-6">Login</h1>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="submit">
          <validation-provider
            v-slot="{ errors }"
            name="username"
            rules="required"
          >
            <v-text-field
              v-model="username"
              :error-messages="errors"
              label="Username"
              outlined
              class="rounded-xl "
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="password"
            rules="required"
          >
            <v-text-field
              v-model="password"
              :error-messages="errors"
              type="password"
              label="Password"
              outlined
              class="rounded-xl"
              required
            ></v-text-field>
          </validation-provider>

          <v-btn
            class="mr-4 rounded-lg"
            block
            type="submit"
            :disabled="invalid"
            @click.prevent="submit()"
          >
            Login
          </v-btn>
        </form>
      </validation-observer>
    </v-container>
    <v-alert
      dense
      text
      type="success"
      v-if="login = success"
    >
      I'm a dense alert with the <strong>text</strong> prop and a <strong>type</strong> of success
    </v-alert>
   
    <v-alert
      dense
      outlined
      type="error"
      v-if="login = error"
    >
      I'm a dense alert with the <strong>outlined</strong> prop and a <strong>type</strong> of error
    </v-alert>
  </div>
</template>
<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import cookie from "@/plugins/cookie"

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    username: "",
    password: "",
    login: ""
  }),

  methods: {
    async submit() {
      this.$refs.observer.validate();
      try {
        const {data} = await this.$axios.post("login", {
          username: this.username,
          password: this.password,
          role: 'student'
        });

        if (data.status == "success"){
          console.log(data.data)
          cookie.setCookie('lpk', JSON.stringify(data.data), 1)
          this.$router.push("/")
        }else{
          console.log(data.message);
        }
      } catch (error) {
        console.log('error')
      }
    },
  },
};
</script>
<style>

.form-container {
  margin-top: 20vh;
}
</style>
