<template class="pb-5">
  <v-app-bar app dark color="red" class="elevation-2  mt-0 pb-2">
    <v-btn fab  class="red darken-2"  @click="goTo()" icon>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  
    <v-toolbar-title class="white--text pl-0 text-right ml-5 d-flex justify-end ">
     
      <div>{{ $store.state.headerTitle }}</div>
    </v-toolbar-title>
  </v-app-bar>
  
</template>
<script>
export default {

  methods: {
    goTo() {
      if (this.$store.state.goBack == "/") {
        this.$router.push("/");
      } else {
        this.$router.push("/" + this.$store.state.goBack);
      }
    },
  },
};
</script>
