<template>
	<div
		class="result d-flex flex-column justify-center align-center text-center"
	>
		<AppBar></AppBar>

		<img v-if="status == 'Lulus'" src="@/assets/img/tick.png" alt="" />
		<img v-else src="@/assets/img/cancel.png" alt="" />
		<h1>{{ status }}</h1>
		<h2>
			Skor Anda: {{ score }}<br />
			benar : {{ benar }} / {{ total }}
		</h2>

		<v-btn
			dark
			class="mt-5 red"
			@click="$router.push('/' + $store.state.goBack)"
			>OK</v-btn
		>
	</div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";
export default {
	components: { AppBar },
	data() {
		return {
			score: 0,
			status: "",
			answered: 0,
			benar: 0,
			total: 0,
		};
	},
	mounted() {
		this.getQuestion();
	},
	methods: {
		async getQuestion() {
			try {
				const { data } = await this.$axios.get(
					"mobile/test/" + this.$route.params.id
				);
				if (data.status == "success") {
					const score = Math.ceil(
						(data.data.correct / data.data.question) * 100
					);
					this.score = score;
					this.benar = data.data.correct;
					this.answered = data.data.answered;
					this.total = data.data.question;
					const batasLulus = 80;
					if (batasLulus <= score) {
						this.status = "Lulus";
					} else {
						this.status = "Tidak Lulus";
					}
				} else {
					console.log("error");
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
<style scoped>
.result {
	height: 90vh;
	width: 100%;
	background: white;
}

.result img {
	height: 25vh;
}
</style>
