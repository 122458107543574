import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import BunpouKotobaView from "../views/bunpou/KotobaView.vue";
import KosakataAltView from "../views/KosakataAltView.vue";
import LoginView from "../views/LoginView.vue";
import BunpouRoomView from "../views/bunpou/RoomView.vue";
import HiraganaView from "../views/HiraganaView.vue";
import KatakanaView from "../views/KatakanaView.vue";
import TesView from "../views/TesView.vue";
import KanjiMenuView from "../views/KanjiMenuView.vue";
import KanjiKosakataView from "@/views/kanji/KanjiKosakataView.vue";
import KanjiDetailView from "@/views/kanji/KanjiDetailView.vue";
import SuujiRoom from "../views/suuji/RoomView.vue";
import SuujiKotobaView from "../views/suuji/KotobaView.vue";
import BunpouPolaKalimat from "../views/bunpou/PolaKalimatView.vue"
import BaseView from "../views/BaseView.vue"
import TestResultView from "@/views/tes/TestResultView.vue"
import AllResultView from "@/views/tes/AllResultView.vue"
import KanjiRoomView from "@/views/kanji/RoomView.vue"
import TesMenuView from "@/views/tes/TesMenuView.vue"
import cookie from '@/plugins/cookie'
Vue.use(VueRouter);



const routes = [
  {
    path: "/",
    redirect: "/menu",
    name: "awal",
    meta: { requiresAuth: true },
    component: BaseView,
    children: [
      {
        path: "/tes-menu",
        name: "tes-menu",
        component: TesMenuView,

      },
      {
        path: "/all-result",
        name: "all-result",
        component: AllResultView,

      },
      {
        path: "/test-result/:id",
        name: "test-result",
        component: TestResultView,

      },
      {
        path: "/bunpou/pola-kalimat:id",
        name: "bunpou-pola-kalimat",
        component: BunpouPolaKalimat,

      },
      {
        path: "/suuji-kotoba/:id",
        name: "suuji-kotoba",
        component: SuujiKotobaView,

      },
      {
        path: "/suuji-room",
        name: "suuji-room",
        component: SuujiRoom,

      },
      {
        path: "/kanji-detail/:id",
        name: "kanji-detail",
        component: KanjiDetailView,

      },
      
      {
        path: "/kanji-menu",
        name: "kanji-menu",
        component: KanjiMenuView,

      },
      {
        path: "/menu",
        name: "menu",
        component: HomeView,

      },
      {
        path: "/tes/:id",
        name: "tes",
        component: TesView,

      },
      {
        path: "/bunpou-room",
        name: "bunpou-room",
        component: BunpouRoomView,

      },
      {
        path: "/kanji-room",
        name: "kanji-room",
        component: KanjiRoomView,

      },
      {
        path: "/kanji/:id",
        name: "kanji",
        component: KanjiKosakataView,

      },

      {
        path: "/hiragana",
        name: "hiragana",
        component: HiraganaView,

      },
      {
        path: "/katakana",
        name: "katakana",
        component: KatakanaView,

      },
      {
        path: "/bunpou/kotoba/:id",
        name: "bunpou-kotoba",
        component: BunpouKotobaView,

      },
      {
        path: "/alt-kosakata",
        name: "alt-kosakata",
        component: KosakataAltView,

      },
      {
        path: "/test-result",
        name: "test-result",
        component: TestResultView,

      },
    ]
  },

  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];




const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  {
    const tokenObj = JSON.parse(cookie.getCookie('lpk'))
    let token = tokenObj ? tokenObj.token : null
    console.log('token:', token)

    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    //     if(from.name == null){
    //       next({name: 'menu'})
    //     }else{
    //       next({name: from.name})
    //     }
    // }else{
    //   next()
    // }
    if (token == null && to.name !== 'login') {
      next('/login');
    } else {
      next();
    }

    // if (token !== null && to.name == 'login') {
    //   console.log('token != null')
    //   next();
    // }
    // else {
    //   console.log('token = null')
    //   next('/login');
    // }
  }

})
export default router;
