<template>
  <div>
    <AppBar title="Kosakata"></AppBar>

    <div class="pa-2">

      <h1>Hiragana</h1>
      <div class="d-flex">
        <div
          class="py-2 mx-2 d-flex align-center justify-middle letter-indicator"
        ></div>
        <v-container
          fluid
          width="100%"
          class="pa-0 d-flex justify-space-around"
        >
          <h3 class="py-2 mx-2 d-flex flex-column align-center justify-center">
            A
          </h3>

          <h3 class="py-2 mx-2 d-flex flex-column align-center justify-center">
            I
          </h3>

          <h3 class="py-2 mx-2 d-flex flex-column align-center justify-center">
            U
          </h3>

          <h3 class="py-2 mx-2 d-flex flex-column align-center justify-center">
            E
          </h3>

          <h3 class="py-2 mx-2 d-flex flex-column align-center justify-center">
            O
          </h3>
        </v-container>
      </div>
      <div class="scroll">
        <container
          class="d-flex"
          fluid
          v-for="(item, index) in desserts"
          :key="index"
        >
          <h3
            class="py-2 mx-2 d-flex align-center justify-middle letter-indicator"
          >
            {{ item.letter }}
          </h3>
          <v-card
            class="rounded-xl d-flex w-full fluid justify-space-around grey lighten-4"
            width="100%"
            outlined
            fluid
          >
            <div
              class="py-2 mx-2 d-flex flex-column align-center justify-center"
            >
              <v-btn
                @click="dialog = true"
                class="d-block"
                elevation="2"
                color="red"
                outlined
                fab
                small
              >
                {{ item.a }}</v-btn
              >
              <small>
                {{ item.a }}
              </small>
            </div>

            <div
              class="py-2 mx-2 d-flex flex-column align-center justify-center"
            >
              <v-btn
                class="d-block"
                elevation="2"
                color="red"
                outlined
                fab
                small
              >
                {{ item.i }}</v-btn
              >
              <small>
                {{ item.i }}
              </small>
            </div>

            <div
              class="py-2 mx-2 d-flex flex-column align-center justify-center"
            >
              <v-btn
                class="d-block"
                elevation="2"
                color="red"
                outlined
                fab
                small
              >
                {{ item.u }}</v-btn
              >
              <small>
                {{ item.u }}
              </small>
            </div>

            <div
              class="py-2 mx-2 d-flex flex-column align-center justify-center"
            >
              <v-btn
                class="d-block"
                elevation="2"
                color="red"
                outlined
                fab
                small
              >
                {{ item.e }}</v-btn
              >
              <small>
                {{ item.e }}
              </small>
            </div>

            <div
              class="py-2 mx-2 d-flex flex-column align-center justify-center"
            >
              <v-btn
                class="d-block"
                elevation="2"
                color="red"
                outlined
                fab
                small
              >
                {{ item.o }}</v-btn
              >
              <small>
                {{ item.o }}
              </small>
            </div>
          </v-card>
        </container>
      </div>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="red">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Hiragana</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>

          <v-card-text key="" class="align-center">
            <div class="container-card align-center d-flex flex-column">
              <v-card
                class="letter-card rounded-xl d-flex relative justify-center align-center"
              >
                <h1>KA</h1>
                <v-btn
                  @click="speech()"
                  icon
                  color="primary"
                  absolute
                  right
                  bottom
                >
                  <v-icon>mdi-volume-high</v-icon>
                </v-btn>

                <v-btn
                  @click="showHint = !showHint"
                  icon
                  color="primary"
                  absolute
                  left
                  bottom
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </v-card>
              <div class="pa-7 hint">
                <h3 v-if="showHint">Ka</h3>
              </div>
            </div>
            <div
              class="container-card align-center d-flex justify-center flex-column"
            >
              <v-card class="letter-card rounded-xl">
                <canvas
                  id="myCanvas"
                  width="200px"
                  height="200px"
                  @mousedown="beginDrawing"
                  @mousemove="keepDrawing"
                  @mouseup="stopDrawing"
                />
              </v-card>
              <v-btn @click="clearCanvas()" class="mt-3">Clear</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import AppBar from "../components/AppBar.vue";
export default {
  components: { AppBar },
  created() {
    // var c = document.getElementById("myCanvas");
    // this.canvas = c.getContext("2d");
  },
  methods: {
    speech() {
      let msg = new SpeechSynthesisUtterance();
      msg.lang = "ja-JP";
      msg.text = "気持ち";
      // var myRate = speechSynthesisUtteranceInstance.rate;
      msg.rate = 0.7;
      window.speechSynthesis.speak(msg);
    },
    showModal() {
      this.dialog = true;
    },
    drawLine(x1, y1, x2, y2) {
      var c = document.getElementById("myCanvas");
      this.canvas = c.getContext("2d");
      let ctx = this.canvas;
      ctx.beginPath();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 10;
      ctx.lineCap = "round";
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
      ctx.closePath();
    },
    beginDrawing(e) {
      this.x = e.offsetX;
      this.y = e.offsetY;
      this.isDrawing = true;
    },
    keepDrawing(e) {
      if (this.isDrawing === true) {
        this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
        this.x = e.offsetX;
        this.y = e.offsetY;
      }
    },
    stopDrawing(e) {
      if (this.isDrawing === true) {
        this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
        this.x = 0;
        this.y = 0;
        this.isDrawing = false;
      }
    },
    clearCanvas() {
      var c = document.getElementById("myCanvas");
      this.canvas = c.getContext("2d");
      let ctx = this.canvas;
      // ctx.clear();
      ctx.clearRect(0, 0, 200, 200);
    },
  },
  data() {
    return {
      selectedWord: "Ka",
      showHint: false,
      dialog: false,
      x: 0,
      y: 0,
      isDrawing: false,
      canvas: null,
      headers: [
        { text: " ", value: "letter" },
        {
          text: "a",
          align: "center",
          sortable: false,
          value: "a",
        },
        { text: "i", value: "i" },
        { text: "u", value: "u" },
        { text: "e", value: "e" },
        { text: "o", value: "o" },
      ],
      desserts: [
        {
          letter: "K",
          a: "ka",
          i: "ki",
          u: "ku",
          e: "ke",
          o: "ko",
        },
        {
          letter: "S",
          a: "sa",
          i: "si",
          u: "su",
          e: "se",
          o: "so",
        },
        {
          letter: "T",
          a: "ta",
          i: "ti",
          u: "tu",
          e: "te",
          o: "to",
        },
        {
          letter: "N",
          a: "na",
          i: "ni",
          u: "nu",
          e: "ne",
          o: "no",
        },
        {
          letter: "H",
          a: "ha",
          i: "hi",
          u: "hu",
          e: "he",
          o: "ho",
        },
        {
          letter: "H",
          a: "ha",
          i: "hi",
          u: "hu",
          e: "he",
          o: "ho",
        },
        {
          letter: "H",
          a: "ha",
          i: "hi",
          u: "hu",
          e: "he",
          o: "ho",
        },
        {
          letter: "H",
          a: "ha",
          i: "hi",
          u: "hu",
          e: "he",
          o: "ho",
        },
        {
          letter: "H",
          a: "ha",
          i: "hi",
          u: "hu",
          e: "he",
          o: "ho",
        },
        {
          letter: "H",
          a: "ha",
          i: "hi",
          u: "hu",
          e: "he",
          o: "ho",
        },
      ],
    };
  },
};
</script>
<style scoped>
.letter-indicator {
  width: 20px;
}
.scroll {
  height: 70vh;
  overflow: auto;
}
.letter-card {
  width: 200px;
  height: 200px;
}
.container-card {
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: center;
}
#canvas {
  width: 100%;
  height: 100%;
}
.hint {
  height: 50px;
}
</style>
