<template>
  <v-dialog
    v-model="newDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="red">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          $store.state.headerTitle + " " + hiragana.kanji
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>

      <v-card-text key="" class="align-center">
        <div
          class="container-card align-center justify-end d-flex flex-column mb-4"
        >
          <v-card
            class="letter-card d-flex relative justify-center align-center"
          >
            <div class="romanji-label">
              {{ hiragana.romanji }}
            </div>
            <img
              :src="BASE_URI + hiragana.gif"
              alt=""
              width="90%"
              height="90%"
            />
            <!-- <h1>{{hiragana.gif}}</h1> -->
            <audio
              ref="audio"
              :src="BASE_URI + hiragana.audio"
              preload
              id="audio"
            ></audio>
            <v-btn
              @click="speech(hiragana.kanji)"
              icon
              color="primary"
              absolute
              right
              bottom
            >
              <v-icon>mdi-volume-high</v-icon>
            </v-btn>

            <v-btn
              @click="showHint = !showHint"
              icon
              color="primary"
              absolute
              left
              bottom
            >
              <v-menu top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                </template>
                <div class="pa-3">
                  {{ hiragana.romanji }}
                </div>
              </v-menu>
            </v-btn>
          </v-card>
        </div>
        <div
          class="container-card align-center d-flex justify-center flex-column"
        >
          <v-card class="letter-card rounded-xl">
            <vue-drawing-canvas
            ref="VueCanvasDrawing"
            :width="240"
            :height="240"
            :stroke-type="strokeType"
            :line-cap="lineCap"
            :line-join="lineJoin"
            :fill-shape="fillShape"
            :eraser="eraser"
            :lineWidth="line"
            :color="color"
            :background-color="backgroundColor"
            :background-image="backgroundImage"
            :watermark="watermark"
            :initial-image="initialImage"
            saveAs="png"
            
            :lock="disabled"
            @mousemove="getCoordinate($event)"
            :additional-images="additionalImages"
          />
          </v-card>
          <v-btn @click.prevent="$refs.VueCanvasDrawing.reset()" class="mt-3">Clear</v-btn>
          
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import VueDrawingCanvas from "vue-drawing-canvas";
import { BASE_URI } from "@/plugins/utilities";
export default {
  components: {
    VueDrawingCanvas,
  },
  props: {
    dialog: Boolean,
    hiragana: {},
  },
  data() {
    return {
      BASE_URI,
      initialImage: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "#000000",
          width: 5,
          fill: false,
        },
      ],
      x: 0,
      y: 0,
      image: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 10,
      color: "#000000",
      strokeType: "dash",
      lineCap: "square",
      lineJoin: "miter",
      backgroundColor: "#FFFFFF",
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
    };
  },
  mounted() {
	this.$refs.VueCanvasDrawing.reset()
  },
  computed: {
    newDialog() {
		if(this.$refs.VueCanvasDrawing){
			this.$refs.VueCanvasDrawing.reset()
		}
      return this.dialog;
    },
  },
  methods: {
    speech() {
      let audio = this.$refs.audio;
      audio.play();
    },
  },
};
</script>
<style scoped>
.romanji-label {
  position: absolute;
  left: 20px;
  top: 15px;
  font-size: 24px;
  color: #1976d2;
  z-index: 100;
}
.letter-indicator {
  width: 20px;
}
.scroll {
  height: 70vh;
  overflow: auto;
}
.letter-card {
  width: 240px;
  height: 240px;
}
.container-card {
  width: 100%;
  height: 43vh;
  display: flex;
  justify-content: center;
}
#canvas {
  width: 100%;
  height: 100%;
}
.hint {
  height: 50px;
}
</style>
