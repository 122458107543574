<template>
	<div>
		<AppBar back="/" title="Kanji"></AppBar>
		<div class="">
			<v-row class="mt-4" no-gutters>
				<v-col class="d-flex justify-center">
					<v-card
						dark
						outlined
						class="menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
						@click="goTo('/kanji')"
					>
						<h3>Kanji 1</h3>
					</v-card>
				</v-col>
				<v-col class="d-flex justify-center">
					<v-card
						dark
						outlined
						class="menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
						@click="goTo('/katakana')"
					>
						<h3>Kanji 2</h3>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-4" no-gutters>
				<v-col class="d-flex justify-center">
					<v-card
						dark
						outlined
						class="menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
						@click="goTo('/bunpou-menu')"
					>
						<h3>Kanji 3</h3>
					</v-card>
				</v-col>
				<v-col class="d-flex justify-center">
					<v-card
						dark
						outlined
						class="menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
						@click="goTo('/kanji-menu')"
					>
						<h3>Kanji 4</h3>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-4" no-gutters>
				<v-col class="d-flex justify-center">
					<v-card
						dark
						outlined
						class="menu-button rounded-circle red dark d-flex justify-center align-center pa-2 text-center"
					>
						<h3>FULL テスト</h3>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import AppBar from "../components/AppBar.vue";
export default {
	components: { AppBar },
	name: "HomeView",
	methods: {
		goTo(route) {
			this.$router.push(route);
		},
	},
};
</script>
<style scoped>
.menu-button {
	width: 150px;
	height: 150px;
	border-width: 3px !important;
}
</style>
